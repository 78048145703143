import Img from 'gatsby-image';
import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';
import { Container, Col, Row, Accordion, AccordionContext, useAccordionToggle } from "react-bootstrap";
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw';
import $ from "jquery";


function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  useEffect(() => {
    var win = $(window);
    win.scroll(function () {
      $('.header').removeClass('sticky-header');
      var sticky = $('.area-guide-detail'),
        scroll = win.scrollTop();

      if (scroll >= 40) sticky.addClass('area-guide-fixed');
      else sticky.removeClass('area-guide-fixed');
    });
  });

  return (
    <button
      type="button"
      className={classNames('toggle-button', { 'active': isCurrentEventKey })}
      onClick={decoratedOnClick}
    >toggle-button</button>
  );
}

const GuidesList = (props) => {
  return (
    <div className="gudies-listing">
      <Container>
        <Row>
          <Col lg="8">
            {props.Guides.map((item, key) => {
              return <>
                <div className="listing-wrap" id={item.Title?.toLowerCase().replace(/\s/g, '')}>
                  <ReactMarkdown rehypePlugins={[rehypeRaw]} children={item.Description}/>
                  {item.Accordions &&
                    <Accordion className="guides-accordion">

                      {item.Accordions.map((accordion) => {
                        return <>
                          <h2>{accordion.Title}</h2><ContextAwareToggle eventKey={accordion.id} />
                          <Accordion.Collapse eventKey={accordion.id}>
                            <ReactMarkdown rehypePlugins={[rehypeRaw]} children={accordion.Content}/>
                          </Accordion.Collapse>
                        </>
                      })}
                    </Accordion>
                  }
                  {item?.Image != null &&
                    <Img fluid={item.Image.childImageSharp.fluid} alt={`${item.Title} - Anthony Pepe`} />
                  }
                </div>
              </>
            })}
            <div class="separator"></div>
          </Col>

        </Row>
      </Container>
    </div>
  )
}
export default GuidesList
