import React, { useState, useRef, useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { Container,Col,Row,Modal } from 'react-bootstrap';
import classNames from 'classnames'
import 'intersection-observer';
import { useInView } from 'react-intersection-observer';
import ScrollAnimation from 'react-animate-on-scroll';
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";
import PlayIcon from '@components/play-icon/play-icon';
import {
    isIOS,
    isMobileOnly,
    isBrowser,
    isMobile
} from "react-device-detect";
/**
 * Assets
 */

 import './styles/_index.scss';

/**
 * Components
 */
import HomeSearch from '@blocks/forms/home-search';
import Video from '@components/video/video';
import Sidebar from '@components/sidebar/sidebar';
import ChatForm from '@components/forms/chat-form';
import ContactBox from '@components/contact-box/contact-box';
import Sticky from 'react-stickynode';
import ReactPlayer from 'react-player';

import $ from 'jquery'
const Wave = (props) => {
    const handleStateChange = (status) => {
        if (status.status === Sticky.STATUS_FIXED) {
            console.log('the component is sticky');
        }
    }    
    const [show, setShow] = useState(false);
    const [play, setPlayvideo] = useState(true);
    const [controls, setVideoControls] = useState(false);
    const [mute, setMute] = useState(true);
    const [showHTMLVideo, setHTMLVideo] = React.useState(false);
    const [, setPlay] = React.useState(false);
    const vidRef = useRef(null);
    const playvideo = (ref) => {
        setPlayvideo(true)
        setVideoControls(false)
    }
    const showControls = () => {
        setVideoControls(true)
    }
    const [ref, inView] = useInView({
        threshold: 0,
        triggerOnce: true,
    });
    const url = typeof window !== 'undefined' ? window.location.href : '';
    const trackerVideo = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Video Play Btn',
            'formType': url,
            'formId': 'Video Play',
            'formName': 'Video',
            'formLabel': 'Video'
        });
    }

    const data = useStaticQuery(graphql`
        query {
        video: file(relativePath: { eq: "video-img.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 2048) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        avatar: file(relativePath: { eq: "avatar.png" }) {
            childImageSharp {
                fluid(maxWidth: 78) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        }
    `);

    const image_url = props?.VideoBG?.internal?.description ? props.VideoBG.internal.description.replace("File ","").replace('"','').replace('"','') : '';  
    let processedImages = JSON.stringify({});
        if (props?.imagetransforms?.Video_Background_Image_Transforms) {
        processedImages = props.imagetransforms.Video_Background_Image_Transforms;
    }
    useEffect(() => {
        setTimeout(function(){ 
            $('.wave').addClass('banneranimation');
        }, 1000);
        setTimeout(function(){ 
            $('.wave').removeClass('banneranimation');
        }, 10000);
    });
 
    return (
        <>
        {showHTMLVideo &&
            <ScrollAnimation animateIn='animate__zoomIn' animateOnce={true} duration={0.3} delay={300}>
                <Modal
                    show={show}
                    onHide={() => setShow(false)}
                    dialogClassName="modal-video"
                    aria-labelledby="example-custom-modal-styling-title"
                    backdropClassName="video-backdrop"
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <ReactPlayer autoplay ref={vidRef} onPlay={trackerVideo(props.Title)} url={props.VideoURL}  controls={false}  muted={false} playsinline={true} playing={play} onEnded={() => { setHTMLVideo(false) }} width='100%' height='100%' />

                    </Modal.Body>
                </Modal>
            </ScrollAnimation>

        }
        <div  className={classNames('wave', {'': inView})} ref={ref}>
            <Container>
                        <Row className="justify-content-between">

            <Col lg="8">
            <ScrollAnimation offset={50} animateIn='animate__animated animate__slideInLeft' animateOnce={true} duration={0.3} delay={300}>      
            <div className={classNames('wave__video video_enclose detail_wave', {'': inView})}>
            <img src={image_url} alt={props.Title} />
            {/* <ImageTransform imagesources={image_url} renderer="srcSet" imagename="area-guides.Video_Background_Image.commontile"attr={{ alt: props.Title+ ' - Anthony Pepe' }} imagetransformresult={processedImages} id={props.id}/> */}
            {props.VideoURL &&
                        <a className="btn-play" href="javascript:;"
                            onClick={() => { setHTMLVideo(true); playvideo(); setShow(true); }}
                        >
                        
                        <PlayIcon size="large"/>
                        </a>
                        }  
            </div>
            </ScrollAnimation>
            </Col>

            <Col lg="4">
            <Sticky  onStateChange={handleStateChange} top={180} bottomBoundary='.separator' enableTransforms={false} className='d-none d-lg-block'>

                <Sidebar>
                    <ChatForm />
                    {/* <div className="sidebar-team-contact spacing-32">
                        <ContactBox name="Stephanie Stavrou" phone="0208 882 6568" link="mailto:test@example.com" linkText="Email" image={data.avatar && data.avatar.childImageSharp.fluid} />
                    </div> */}
                    
                </Sidebar>
                </Sticky>

            </Col>

                    </Row>

            </Container>
            <div className="wave-search">
                <Container>
                {props.Showreview == true &&
                    <HomeSearch />
                }
                </Container>
            </div>

        </div>
        </>
    )
}

export default Wave
